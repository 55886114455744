import type { RouteRecordRaw } from 'vue-router'

import adminMiddleware from '@/router/middleware/adminMiddleware'
import authMiddleware from '@/router/middleware/authMiddleware'
import guestMiddleware from '@/router/middleware/guestMiddleware'

export default <RouteRecordRaw[]>[
    /* ------------ */
    /* Start Layout */
    /* ------------ */
    {
        path: '/',
        component: async () => import('@/layouts/StartLayout.vue'),
        meta: { middleware: [guestMiddleware] },
        children: [
            {
                path: '/',
                name: 'Inicio',
                component: async () => import('@/pages/WelcomePage.vue'),
            },
            {
                path: '/login',
                name: 'Login',
                component: async () => import('@/pages/auth/LoginPage.vue'),
            },
        ],
    },
    /* ------------- */
    /*  Main Layout  */
    /* ------------- */
    {
        path: '/app',
        component: async () => import('@/layouts/MainLayout.vue'),
        meta: { middleware: [authMiddleware] },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: async () => import('@/pages/DashboardPage.vue'),
            },
            {
                path: '/test',
                name: 'Test',
                component: async () => import('@/pages/TestPage.vue'),
            },
            /* Ventas Routes */
            {
                path: '/ventas/nueva',
                name: 'NuevaVenta',
                component: async () => import('@/pages/ventas/NuevaVenta.vue'),
            },
            {
                path: '/sucursal/operaciones',
                name: 'OperiacionesLocal',
                component: async () => import('@/pages/local/OperacionesPage.vue'),
            },
            {
                path: '/ventas/articulos/vendidos',
                name: 'ArticulosVendidos',
                component: async () => import('@/pages/ventas/articulos/ArticulosVendidosPage.vue'),
            },
            {
                path: '/ventas',
                name: 'Ventas',
                component: async () => import('@/pages/ventas/VentasPage.vue'),
            },
            {
                path: '/ventas/:id',
                name: 'DocumentoVenta',
                component: async () => import('@/pages/ventas/DocumentoVenta.vue'),
                props: true,
            },
            /* Documentos */
            {
                path: '/gobierno/documentos',
                name: 'DocumentosGobierno',
                component: async () => import('@/pages/gobierno/documentos/DocumentosGobierno.vue'),
            },
            /* Vouchers Routes */
            {
                path: '/vouchers',
                name: 'Vouchers',
                component: async () => import('@/pages/vouchers/VouchersPage.vue'),
            },
            /* Reclamos Routes */
            {
                path: '/reclamos/nuevo',
                name: 'NuevoReclamo',
                component: async () => import('@/pages/reclamos/NuevoReclamo.vue'),
            },
            {
                path: '/reclamos',
                name: 'Reclamos',
                component: async () => import('@/pages/reclamos/ReclamosPage.vue'),
            },
            {
                path: '/reclamos/:id',
                name: 'DocumentoReclamo',
                component: async () => import('@/pages/reclamos/DocumentoReclamo.vue'),
                props: true,
            },
            /* Etiquetas Routes */
            {
                path: '/etiquetas',
                name: 'Etiquetas',
                component: async () => import('@/pages/stock/etiquetas/EtiquetasPage.vue'),
            },
            /* Stock Routes */
            {
                path: '/stock/control',
                name: 'ControlStock',
                component: async () => import('@/pages/stock/control/ControlStockPage.vue'),
            },
            {
                path: '/stock/control/:id',
                name: 'DocumentoControlStock',
                component: async () => import('@/pages/stock/control/DocumentoControlStock.vue'),
                props: true,
            },
            {
                path: '/stock/control/:id/articulos',
                name: 'ControlStockInsert',
                component: async () => import('@/pages/stock/control/ControlStockInsert.vue'),
                props: true,
            },
            {
                path: '/movimientos/stock',
                name: 'MovimientosStock',
                component: async () => import('@/pages/stock/consultas/MovimientosStockPage.vue'),
            },
            /* Movimientos de Transferencia de Stock Routes */
            {
                path: '/movimientos/transferencias',
                name: 'Transferencias',
                component: async () => import('@/pages/stock/transferencias/TransferenciasPage.vue'),
            },
            {
                path: '/movimientos/transferencias/nuevo',
                name: 'NuevaTransferencia',
                component: async () => import('@/pages/stock/transferencias/NuevaTransferencia.vue'),
            },
            {
                path: '/movimientos/transferencias/:id',
                name: 'DocumentoTransferencia',
                component: async () => import('@/pages/stock/transferencias/DocumentoTransferencia.vue'),
                props: true,
            },
            /* Movimientos de Stock Routes */
            {
                path: '/movimientos',
                name: 'Movimientos',
                component: async () => import('@/pages/stock/movimientos/MovimientosPage.vue'),
            },
            {
                path: '/movimientos/nuevo',
                name: 'NuevoMovimiento',
                component: async () => import('@/pages/stock/movimientos/NuevoMovimiento.vue'),
            },
            {
                path: '/movimientos/:id',
                name: 'DocumentoMovimiento',
                component: async () => import('@/pages/stock/movimientos/DocumentoMovimiento.vue'),
                props: true,
            },
            /* Clients Routes */
            {
                path: '/clientes',
                name: 'Clientes',
                component: async () => import('@/pages/clientes/ClientesPage.vue'),
            },
            {
                path: '/clientes/:id',
                name: 'DocumentoCliente',
                component: async () => import('@/pages/clientes/DocumentoCliente.vue'),
                props: true,
            },
            /* Pedidos Routes */
            {
                path: '/pedidos/insumos',
                name: 'PedidosInsumos',
                component: async () => import('@/pages/pedidos/insumos/PedidosInsumosPage.vue'),
            },
            {
                path: '/pedidos/mantenimiento',
                name: 'PedidosMantenimiento',
                component: async () => import('@/pages/pedidos/mantenimiento/PedidosMantenimientoPage.vue'),
            },
            /* Informes Routes */
            {
                path: '/informes/vendedores',
                name: 'InformeVendedores',
                component: async () => import('@/pages/informes/InformeVendedoresPage.vue'),
            },
            {
                path: '/informes/anual',
                name: 'InformeAnual',
                component: async () => import('@/pages/informes/InformeAnualPage.vue'),
            },
            /* Portals Routes */
            {
                path: '/portal/vendedor',
                name: 'PortalVendedor',
                component: async () => import('@/pages/portals/VendedorPortalPage.vue'),
            },
            /* Settings Routes */
            {
                path: '/perfil',
                name: 'Profile',
                component: async () => import('@/pages/settings/ProfilePage.vue'),
            },
            /* ------------ */
            /* Admin Routes */
            /* ------------ */
            {
                path: '/admin',
                meta: { middleware: [adminMiddleware] },
                children: [
                    /* Facturar Documentos gobierno */
                    {
                        path: '/gobierno/facturar',
                        name: 'FacturarAdmin',
                        component: async () => import('@/pages/admin/gobierno/FacturarPage.vue'),
                    },
                    /* Stock Expense Routes */
                    {
                        path: '/expensas',
                        name: 'Expense',
                        component: async () => import('@/pages/admin/expense/ExpensePage.vue'),
                    },
                    /* Reports Routes */
                    {
                        path: '/descargas',
                        name: 'Descargas',
                        component: async () => import('@/pages/exports/descargas/DescargasPage.vue'),
                    },
                    {
                        path: '/informes/sucursales',
                        name: 'InformeSucursales',
                        component: async () => import('@/pages/admin/informes/InformeSucursalesPage.vue'),
                    },
                    /* Import Routes */
                    {
                        path: '/importar',
                        name: 'Importar',
                        component: async () => import('@/pages/imports/ImportPage.vue'),
                    },
                    /* Users Routes */
                    {
                        path: '/users',
                        name: 'Users',
                        component: async () => import('@/pages/admin/user/UsersPage.vue'),
                    },
                    {
                        path: '/users/:id',
                        name: 'DocumentoUser',
                        component: async () => import('@/pages/admin/user/DocumentoUser.vue'),
                        props: true,
                    },
                    /* MedioPago Routes */
                    {
                        path: '/mediosPagos',
                        name: 'MediosPagos',
                        component: async () => import('@/pages/admin/mediosPagos/MediosPagosPage.vue'),
                    },
                    /* Asistencias Routes */
                    {
                        path: '/asistencias',
                        name: 'Asistencias',
                        component: async () => import('@/pages/asistencias/AsistenciaPage.vue'),
                    },
                    /* Bancos Routes */
                    {
                        path: '/bancos',
                        name: 'Bancos',
                        component: async () => import('@/pages/admin/bancos/BancosPage.vue'),
                    },
                    /* Promos Routes */
                    {
                        path: '/promos',
                        name: 'Promos',
                        component: async () => import('@/pages/admin/promos/PromosPage.vue'),
                    },
                    /* Objetivos Routes */
                    {
                        path: '/objetivos',
                        name: 'Objetivos',
                        component: async () => import('@/pages/admin/objetivos/ObjetivosPage.vue'),
                    },
                    /* SellOut Routes */
                    {
                        path: '/sell-out',
                        name: 'SellOut',
                        component: async () => import('@/pages/admin/rotacionMercaderia/SellOutPage.vue'),
                    },
                    /* Vendedores Routes */
                    {
                        path: '/vendedores',
                        name: 'Vendedores',
                        component: async () => import('@/pages/admin/vendedores/VendedoresPage.vue'),
                    },
                    /* Licencias Routes */
                    {
                        path: '/vendedores/licencias',
                        name: 'Licencias',
                        component: async () => import('@/pages/admin/vendedores/licencias/LicenciasPage.vue'),
                    },
                    /* Compras Routes */
                    {
                        path: '/compras',
                        name: 'Compras',
                        component: async () => import('@/pages/admin/compras/ComprasPage.vue'),
                    },
                    {
                        path: '/compras/nuevo',
                        name: 'NuevaCompra',
                        component: async () => import('@/pages/admin/compras/CompraNueva.vue'),
                    },
                    /* Libro Compras Routes */
                    {
                        path: '/compras/libros',
                        name: 'LibroCompras',
                        component: async () => import('@/pages/admin/compras/libros/LibrosComprasPage.vue'),
                    },
                    /* Cheques Routes */
                    {
                        path: '/cheques',
                        name: 'Cheques',
                        component: async () => import('@/pages/admin/cheques/ChequesPage.vue'),
                    },
                    /* Pagos Routes */
                    {
                        path: '/pagos',
                        name: 'Pagos',
                        component: async () => import('@/pages/admin/pagos/PagosPage.vue'),
                    },
                    {
                        path: '/pagos/nuevo',
                        name: 'NuevoPago',
                        component: async () => import('@/pages/admin/pagos/PagoNuevo.vue'),
                    },
                    /* Proveedores Routes */
                    {
                        path: '/proveedores',
                        name: 'Proveedores',
                        component: async () => import('@/pages/admin/proveedores/ProveedoresPage.vue'),
                    },
                    /* Ajustes Proveedores Routes */
                    {
                        path: '/proveedores/ajustes',
                        name: 'ProveedoresAjustes',
                        component: async () => import('@/pages/admin/proveedores/ajustes/ProveedoresAjustesPage.vue'),
                    },
                    /* Marcas Routes */
                    {
                        path: '/marcas',
                        name: 'Marcas',
                        component: async () => import('@/pages/admin/proveedores/marcas/MarcasPage.vue'),
                    },
                    /* Percepciones Routes */
                    {
                        path: '/percepciones',
                        name: 'Percepciones',
                        component: async () => import('@/pages/admin/percepciones/PercepcionesPage.vue'),
                    },
                    /* Articulos Routes */
                    {
                        path: '/articulos',
                        name: 'Articulos',
                        component: async () => import('@/pages/admin/articulos/ArticulosPage.vue'),
                    },
                    {
                        path: '/productos',
                        name: 'Productos',
                        component: async () => import('@/pages/admin/articulos/productos/ProductosPage.vue'),
                    },
                    {
                        path: '/colores',
                        name: 'Colores',
                        component: async () => import('@/pages/admin/articulos/colores/ColoresPage.vue'),
                    },
                    {
                        path: '/talles',
                        name: 'Talles',
                        component: async () => import('@/pages/admin/articulos/talles/TallesPage.vue'),
                    },
                    {
                        path: '/categorias',
                        name: 'Categorias',
                        component: async () => import('@/pages/admin/articulos/categorias/CategoriasPage.vue'),
                    },
                    /* Sucursales Routes */
                    {
                        path: '/sucursales',
                        name: 'Sucursales',
                        component: async () => import('@/pages/admin/sucursales/SucursalesPage.vue'),
                    },
                    {
                        path: '/sucursales/marcas/coeficientes',
                        name: 'CoeficientesSucursalesMarcas',
                        component: async () => import('@/pages/admin/sucursales/coeficientesMarcas/CoeficientesSucursalesMarcasPage.vue'),
                    },
                    /* Sueldos Routes */
                    {
                        path: '/sueldos/obras-sociales',
                        name: 'ObrasSociales',
                        component: async () => import('@/pages/admin/sueldos/obras_sociales/ObrasSocialesPage.vue'),
                    },
                    {
                        path: '/sueldos/modelos',
                        name: 'SueldosModelos',
                        component: async () => import('@/pages/admin/sueldos/modelos/ModelosPage.vue'),
                    },
                    {
                        path: '/sueldos/conceptos',
                        name: 'SueldosConceptos',
                        component: async () => import('@/pages/admin/sueldos/conceptos/ConceptosPage.vue'),
                    },
                    {
                        path: '/sueldos/horas',
                        name: 'SueldosHorasTrabajadas',
                        component: async () => import('@/pages/admin/sueldos/horas/HorasTrabajadasPage.vue'),
                    },
                    {
                        path: '/sueldos/categorias',
                        name: 'SueldosCategorias',
                        component: async () => import('@/pages/admin/sueldos/categorias/CategoriasPage.vue'),
                    },
                    {
                        path: '/sueldos/recibos',
                        name: 'SueldosRecibos',
                        component: async () => import('@/pages/admin/sueldos/recibos/RecibosPage.vue'),
                    },
                    {
                        path: '/sueldos/personal',
                        name: 'SueldosPersonalExtra',
                        component: async () => import('@/pages/admin/sueldos/personal/PersonalExtraPage.vue'),
                    },
                    {
                        path: '/sueldos/extras',
                        name: 'SueldosExtras',
                        component: async () => import('@/pages/admin/sueldos/extras/SueldosExtrasPage.vue'),
                    },
                ],
            },
        ],
    },
    /* Error Routes */
    {
        path: '/error',
        component: async () => import('@/layouts/StartLayout.vue'),
        children: [
            {
                path: '/actualizando',
                name: '503',
                component: async () => import('@/pages/errors/ErrorPage503.vue'),
            },
            {
                path: '/:pathMatch(.*)*',
                name: '404',
                component: async () => import('@/pages/errors/ErrorPage404.vue'),
            },
        ],
    },
]
